import './App.css';
import React,{useEffect,useState} from 'react'
function App() {
  const [users,setUser]=useState([])
  useEffect(()=>{
    fetch("https://mina--mainnet--indexer.datahub.figment.io/apikey/b7a89ff599271e860f64120eded1f32b/blocks?limit=10").then((result)=>{
      
      result.json().then((resp)=>{
        // console.warn(resp)
        setUser(resp)
      },)
    })
  },[])
  console.warn(users)
  return (
    <div className="App">
      <h1>blocks </h1>
      <table border="1">
       <tbody>
       <tr>
       <td>Height</td>
        <td>Hash</td>
        <td>Time</td>
        <td>Creator</td>
        <td>epoch</td>
        <td>Slot</td>
        <td>Transactions Count</td>
        </tr>
        {
          users.map((item,i)=>
            <tr key={i}>
            <td>{item.height}</td>
          <td>{item.hash}</td>
          <td>{item.time}</td>
            <td>{item.creator}</td>
            <td>{item.epoch}</td>
            <td>{item.slot}</td>
            <td>{item.transactions_count}</td>
          </tr>
          )
        }
       </tbody>
      </table>
    </div>
  );
}
export default App;

